import React from 'react';
import { graphql } from "gatsby";
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from '../../components/layout.js';
import SEO from '../../components/seo.js';
import PageHeading from '../../components/page-heading.js';
import Video from '../../components/video.js';
import '../../styles/article.scss';
import '../../styles/case-study.scss';
import Contact from '../../components/contact.js';

const CaseStudy = ({data}) => {
    return (
        <Layout background="stone">
            <SEO 
              title="COAT Paints: A Shopify Plus Case Study – 54 Bit Studios" 
              description="How 54 Bit Studios transformed the COAT Paints website to boost conversion rates and meet the demands of their rapidly expanding customer base."
              image="../assets/video/testimonial-poster.jpg"
              article={true}/>
            <PageHeading h1="COAT Paints: A Shopify Plus Case Study" h2="Case Study" blurb="How 54 Bit Studios transformed the COAT Paints website to boost conversion rates and meet the demands of their rapidly expanding customer base." />
            <Video header={false} className="inline" />
            <section className="ff article">
                <h3>Overview</h3>
                <p>COAT Paints are a rapidly growing direct-to-consumer online paint company that have a strong focus on quality and sustainability. Founded in 2020 by Rob Abrahams and Rob Green, they sought a comprehensive overhaul of their online store to meet the demands of their expanding customer base. So, as they recognised the pivotal role of a robust online platform, COAT Paints partnered with us, 54 Bit Studios.</p>
                <p>The primary objective of our work together was to completely redesign and redevelop the COAT Paints website. This led to creating an e-commerce store that drove sales, enhanced conversion rates and drastically improved the customer experience.</p>
                <h3>Approach</h3>
                <p>Following rapid growth, COAT Paints faced several challenges with their website:</p>
                <ul className="numbered">
                    <li>As a business heavily reliant on paid advertising, they faced the challenge of optimising their website to maximise the return on investment from their paid media campaigns and drive conversions.</li>
                    <li>Their old website was an out-dated, prebuilt Shopify theme and was difficult to maintain, limiting the website's flexibility and scalability.</li>
                    <li>Their website struggled to keep up with the business growth. It wasn’t adhering to best practices and bugs were creeping in which negatively impacted load times and user experience.</li>
                </ul>
                <p>COAT Paints chose us to help with this project thanks to their previous positive experience of working with our CEO, James Dix, when he was in a different role before starting the agency.  To solve the challenges above, it was clear we needed to do a redesign of the website. However; before embarking on the project, we made sure the design vision could be translated into a technically sound and efficient website.</p>
                <p>Once the design had been confirmed, we divided the project into three phases:</p>
                <ul className="numbered">
                    <li>Foundations and global components</li>
                    <li>Transactional</li>
                    <li>Ancillary</li>
                </ul>
                <p>Each phase underwent rigorous quality assurance with our team before release, and we implemented a phased release strategy to ensure a smooth transition to the new website. We used SEO best practices throughout the process, which resulted in improved traffic after launch.</p>
            </section>
            <section className="quote-container">
                <div className="quote">
                    <p className="ff">We’ve been working with 54 Bit Studios for three years. Initially, our site was built by an outsourced Shopify developer and 54 Bit Studios have taken on the growth since then. Over a three-year period, we had 10 times as many products and our revenue was increasing at a similar rate. So, James and the 54 Bit Studios team have been working to improve the website and enhance it.</p>
                    <p className="ff">Deliverables from 54 Bit Studios have always been amazing. We have a really great working relationship. And clearly the success factors have been evident throughout our work together. We've managed to scale as a business alongside our revenue, improve the traffic through the site and boost the conversion rate. There are so many metrics I could go into that demonstrate why we've carried on working with 54 Bit Studios</p>
                    <p className="ff">As a growing business, we’ve had to scale resources up and down when it comes to web development. And 54 Bit Studios have always been really receptive and flexible around this, which is a huge positive for us and a massive help to us as a business.</p>
                    <p className="ff">When you work with a bigger agency, you can lose a bit of the human attention and the care because you're chucked into an account management function. But a massive benefit of 54 Bit Studios is that direct relationship with the developer. Additionally, the team’s Shopify expertise has saved us a lot of time as we gained insights into how things fit together and work from that perspective.</p>
                    <p className="ff">I think the thing that makes 54 Bit Studios better than competitors is their attitude- that ‘anything is possible’ mindset. It's always about solutions, not problems and this is quite uncommon in the web development industry.  However, 54 Bit Studios have always said “yes, it's possible, but the ‘How’ is what we need to look at. And that looks like XYZ.” So, for me, the best thing about working with 54 Bit Studios is that proactive attitude.</p>
                    <p className="ff">Finally, the team's attention to detail is second to none. For example, the deployment schedule is quick and their ability to fix the inevitable bugs is great. Whereas some agencies and freelancers won’t care if your website is on fire if it’s outside of the 9-5.</p>
                    <p className="ff">That kind of stuff feels very different with 54 Bit Studios.”</p>
                    <p className="ff-bold">- Rob Abrahams, COAT Paints </p>
                </div>
            </section>
            <section className="ff article">
                <h3 className="ff">Results</h3>
                <p>Our partnership with COAT Paints led to a brand-new website with huge improvements in performance. The redesigned site not only enhances the user experience through a fresh, leaner look and site navigation, it also has cleaner and more powerful codebase. This has helped to improve the site’s loading speed and made it more adaptable and efficient for future growth. Additionally, we created a robust solution for searching, filtering and sorting that highlights the most relevant paint for customers during their discovery phase. This was especially important as COAT Paints have complex merchandising requirements.</p>
                <p>The new website has resulted in a <span class="ff-bold">higher volume of organic traffic</span> and an <span class="ff-bold">improved conversion rate</span> for the COAT Paints online store. <span class="ff-bold">AOV improved by 24%</span> immediately following the launch of the Transactional pages.</p>
                <h3 className="ff">Conclusion</h3>
                <p>Our partnership with COAT Paints has been a resounding success, resulting in a significantly improved online presence. The redesigned and redeveloped site, built upon a robust and scalable codebase, has not only enhanced the user experience but has also driven increased sales and conversions.</p>
                <p>Our solution addressed all of the challenges COAT Paints faced with their previous website and our expertise and collaborative approach was instrumental in delivering a successful outcome. COAT Paints are now perfectly positioned for their continued growth in the competitive online paint market.</p>
                <p>We're happy to say that we remain committed to support COAT Paints' online success by providing ongoing maintenance and optimisations as needed. No "build and bye" here!</p>
            </section>
            <Contact heading="Get in touch" subheading="If you need any help getting your store primed and ready for its best weekend ever, let’s chat!" />
        </Layout>
    )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "blog/off-the-shelf.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default CaseStudy